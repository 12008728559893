import { NetworkConfig } from "config/app.config";
import {
  CollectionCategory,
  UnlockdCollectionData,
} from "logic/types/collection/ICollection";
import {
  CurrencyData,
  Erc20CurrencyData,
} from "vendor/@unlockdfinance/verislabs-web3/src/types";

const USDC_CURRENCY: Erc20CurrencyData = {
  decimals: 6,
  decimalsToShow: 2,
  srcPrimary: "/assets/images/tokens/usdc.png",
  srcSecondary: "/assets/images/tokens/usdc-secondary.png",
  name: "usdc",
  label: "USDC",
  address: "0x41E94Eb019C0762f9Bfcf9Fb1E58725BfB0e7582",
  // TODO
  chainlinkAddress: "0x1b8739bB4CdF0089d07097A9Ae5Bd274b29C6F16",
  type: "erc20",
  chainId: 80002,
};

const MATIC_CURRENCY: CurrencyData = {
  decimals: 18,
  decimalsToShow: 4,
  // TODO
  srcPrimary: "/assets/images/tokens/eth.png",
  // TODO
  srcSecondary: "/assets/images/tokens/eth-secondary.png",
  name: "matic",
  label: "MATIC",
  chainlinkAddress: "0x001382149eBa3441043c1c66972b4772963f5D43",
  type: "native",
  chainId: 80002,
};

const UNLOCKD_TEST_WATCHES: UnlockdCollectionData = {
  name: "Unlockd Amoy Watches NFT",
  address: "0x6E6DF8330DF6d167F337BFd4CD0158110b8312f7",
  currenciesSupported: [USDC_CURRENCY],
  genericImage:
    "https://cdn.watches.io/content/watch_images/cnukwil3c6abnntblwbapngsizelarge",
  category: CollectionCategory.RWA,
};

// const UNLOCKD_TEST_WATCHES: UnlockdCollectionData = {
//   name: "Unlockd Test Watches",
//   address: "0xA6a9AcFDD1F64EC324eE936344cDB1457BDbDDF0",
//   currenciesSupported: [USDC_CURRENCY],
//   genericImage:
//     "https://cdn.watches.io/content/watch_images/cnukwil3c6abnntblwbapngsizelarge",
//   category: CollectionCategory.RWA,
// };

export const polygonAmoyApp: NetworkConfig = {
  FAUCET_COLLECTIONS: [UNLOCKD_TEST_WATCHES],
  COLLECTIONS: [UNLOCKD_TEST_WATCHES],
  CONTRACT_ADDRESSES: {
    unlockd: "0x567136ccdFB320076dFEa5deFB65538D6737F7a7",
    uTokenFactory: "0xaEE2941b6E78858E203036E34110063EFc8350D4",
    walletFactory: "0x661Fa5cE705D6AcdA108a6323B3f2732345b0b45",
    walletRegistry: "0x70951076369c376578Fa3E808b1b148bB5177fe2",
    bulkTransfer: "0x7a18Bc13AfcF9eC02E63ab08129D1fA5fb283Ca6",

    // TODO
    pushChannel: "0x",

    // NOT IN USE
    wrappedToken: "0x0000000000000000000000000000000000000000",
    earnWethGateway: "0x0000000000000000000000000000000000000000",
    delegationController: "0x0000000000000000000000000000000000000000",
  },
  ALCHEMY: {
    rpcUrl: "https://polygon-amoy.g.alchemy.com/v2",
    apiUrl: "https://polygon-amoy.g.alchemy.com/nft/v2",
  },
  CHAIN: {
    name: "polygon-amoy",
    hexId: "0x13882",
    id: 80002,
  },
  FUNCTIONALITIES: {
    faucet: true,
    "eth-wrapper": false,
  },
  CURRENCIES: [MATIC_CURRENCY, USDC_CURRENCY],
  TOKENS: [MATIC_CURRENCY, USDC_CURRENCY],
  images: {
    srcPrimary: "/assets/images/tokens/polygon.svg",
    srcSecondary: "/assets/images/tokens/polygon-secondary.svg",
    srcTertiary: "/assets/images/tokens/polygon-violet.png",
  },
  RESERVOIR_API_URL: "https://api-amoy.reservoir.tools/",
  UNLOCKD_API_URL: "https://polygon-amoy.staging.unlockd.finance",
} as const;
